import React from 'react';
import {PageHeader} from "../components/page-header";
import {ContactForm} from "../components/contact-form";
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import L from 'leaflet';

function Kontakt() {
    var lIcon = L.icon({
        iconUrl: '/logo32.png',
        iconSize:     [32, 32], // size of the icon
        iconAnchor:   [16, 16], // point of the icon which will correspond to marker's location
    });

    return (
        <>
            <PageHeader title={"Kontakt"}/>
            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-10 2xl:gap-40 py-10 px-10 md:px-20 font-normal">
                <div>
                    Leider können wir derzeit keine Neupatienten aufnehmen.
                    <br/><br/>
                    Wir bemühen uns, Ihre Telefonanrufe entgegenzunehmen. Sollten Sie uns nicht erreichen, schreiben Sie uns gerne eine E-Mail an <a className="text-primary" href="mailto:info@praxis-lohkamp.de">info@praxis-lohkamp.de</a>
                    <br/><br/>
                    Für Folgerezepte oder Überweisungen (HZV-Patienten) können Sie uns eine Nachricht auf dem Anrufbeantworter hinterlassen.
                    <div className={"mt-6 grid grid-cols-3"}>
                        <div>
                            Telefon:
                        </div>
                        <div className={"col-span-2 text-end"}>
                            <a href="tel: 49405705321"> +49 40 5705321</a>
                        </div>
                        <div>
                            Fax:
                        </div>
                        <div className={"col-span-2 text-end"}>
                            +49 40 5703094
                        </div>
                        <div>
                            E-Mail:
                        </div>
                        <div className={"col-span-2 text-end"}>
                            <a href="mailto:info@praxis-lohkamp.de">info@praxis-lohkamp.de</a>
                        </div>
                    </div>
                    <div className={"mt-6 grid grid-cols-3"}>
                        <div>
                            Praxisadresse:
                        </div>
                        <div className={"col-span-2 text-end"}>
                            <a href="https://www.google.com/maps/place/Lohkampstra%C3%9Fe+11,+22523+Hamburg" target="_blank">Lohkampstraße 11<br/>22523 Hamburg</a>
                        </div>

                    </div>

                </div>
                <div className={"grid grid-cols-3 gap-2 h-fit"}>
                    <div className={"col-span-3"}>
                        <h3>
                            Öffnungszeiten
                        </h3>
                    </div>
                    <div className={"col-span-3 pt-4 text-accent"}>
                        <h3>
                            Akutsprechstunde
                        </h3>
                    </div>
                    <div>Montag - Freitag:</div>
                    <div className={"text-end col-span-2"}>8:30 - 9:30 Uhr</div>
                    <div className={"col-span-3 pt-4 text-accent"}>
                        <h3>
                            Terminsprechstunde
                        </h3>
                    </div>
                    <div>Montag:</div>
                    <div className={"text-end col-span-2"}>9:30 - 13:00 Uhr</div>
                    <div>Dienstag:</div>
                    <div className={"text-end col-span-2"}>9:30 - 13:00 Uhr<br/>15:00 - 18:00 Uhr</div>
                    <div>Mittwoch:</div>
                    <div className={"text-end col-span-2"}>9:30 - 13:00 Uhr</div>
                    <div>Donnerstag:</div>
                    <div className={"text-end col-span-2"}>9:30 - 13:00 Uhr<br/>15:00 - 18:00 Uhr</div>
                    <div>Freitag:</div>
                    <div className={"text-end col-span-2"}>9:30 - 13:00 Uhr</div>
                </div>
                <div>
                    <ContactForm header={"Schreiben Sie uns"}/>
                </div>
            </div>
            <div id={"map"} className={"w-full h-[250px] xl:h-[420px] "}>
                <MapContainer center={[53.608665, 9.902046]} zoom={13} scrollWheelZoom={false} className={"w-full h-full"}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker icon={lIcon} position={[53.608665, 9.902046]}/>
                </MapContainer>
            </div>
        </>
    );
}

export default Kontakt;